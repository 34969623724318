<template>
  <v-row>
    <v-col cols="12">
      <v-card
        :loading="loading"
      >
        <div class="pa-4">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <h4 class="primary--text">
                Diskusi
              </h4>
              <span class="text-subtitle-2">Rangkuman diskusi yang sedang kamu ikuti.</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="auto"
              class="py-0"
            >
              <v-tabs
                v-model="objectFilter"
                :right="false"
                @change="fetchActivityComments"
              >
                <v-tab>
                  Task
                </v-tab>
                <v-tab>
                  Job
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
    <v-col
      v-if="!activityCommentList.length"
      cols="12"
    >
      <div
        class="d-flex justify-center align-center mt-8"
      >
        <span class="text--disabled">Belum ada diskusi</span>
      </div>
    </v-col>
    <v-col
      v-for="(activity, index) in activityCommentList"
      :key="index"
      cols="12"
      md="6"
      lg="4"
    >
      <comment-box
        :key="index"
        expand
        toggleable
        expand-height="20"
        :job-id="activity.job_id"
        :task-id="activity.task_id"
      >
        <template #header>
          <v-card
            outlined
            class="pa-2 mr-2 d-flex align-center"
            @click="resolveHeaderClick(activity)"
          >
            <span class="text-subtitle-2 primary--text mr-4">
              {{ activity.name }}
            </span>
            <v-icon
              size="20"
              class="primary--text"
            >
              {{ icons.mdiOpenInNew }}
            </v-icon>
          </v-card>
        </template>
      </comment-box>
    </v-col>

    <task-detail
      ref="taskDetail"
    />
  </v-row>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiOpenInNew } from '@mdi/js'
import CommentBox from '@/components/misc/CommentBox.vue'
import { apolloClient } from '@/vue-apollo'
import { activityComment } from '@/graphql/queries'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import router from '@/router'
import TaskDetail from '@/views/activity/task/TaskDetail.vue'

export default {
  components: {
    CommentBox,
    TaskDetail,
  },
  setup() {
    const loading = ref(false)
    const objectFilter = ref()
    const activityCommentList = ref([])
    const taskDetail = ref()

    const fetchActivityComments = async () => {
      loading.value = true

      const objectVisibility = {}

      if (objectFilter.value === 0) objectVisibility.is_task = true
      if (objectFilter.value === 1) objectVisibility.is_job = true

      apolloClient.query({
        query: activityComment,
        variables: {
          pagination: {
            limit: 12,
            offset: 0,
          },
          ...objectVisibility,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        loading.value = false
        activityCommentList.value = result.data.activityComment
      }).catch(err => {
        loading.value = false
        errorHandling(err)
      })
    }

    const resolveHeaderClick = data => {
      if (data.job_id) {
        router.push({
          name: 'job-detail',
          params: {
            id: data.job_id,
          },
        })
      }

      if (data.task_id) {
        console.log('task')
      }

      return null
    }

    onMounted(() => {
      fetchActivityComments()
    })

    return {
      loading,
      objectFilter,
      activityCommentList,
      taskDetail,

      fetchActivityComments,
      resolveHeaderClick,

      icons: {
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style>

</style>
