<template>
  <div
    id="activity"
    :class="currentTab == 0 ? 'max-h-content-container position-relative overflow-hidden' : null"
  >
    <v-tabs
      v-model="currentTab"
      class="rounded activity-tabs mb-4"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.label"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span class="text-capitalize text-subtitle-2">{{ tab.label }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="currentTab"
      touchless
    >
      <v-tab-item>
        <Task />
      </v-tab-item>
      <v-tab-item>
        <Comment />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { useStorage } from '@vueuse/core'
import {
  mdiCalendarCheck, mdiForum,
} from '@mdi/js'
import Task from '@/views/activity/task/Task.vue'
import Comment from '@/views/activity/comment/Comment.vue'

export default {
  components: {
    Task,
    Comment,
  },
  setup() {
    const currentTab = useStorage('activityTab', 0)
    const tabs = [
      { label: 'Task', icon: mdiCalendarCheck },
      { label: 'Diskusi', icon: mdiForum },
    ]

    return {
      currentTab,
      tabs,
    }
  },
}
</script>

<style lang="scss">
#activity {
  .activity-tabs {
    &.v-tabs:not(.v-tabs--vertical) {
      box-shadow: none !important;
      .v-tabs-bar {
        background-color: transparent !important;
      }
    }
  }

  .v-tabs-items {
    background-color: transparent !important;
  }
}
</style>
