import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"primary--text"},[_vm._v(" Diskusi ")]),_c('span',{staticClass:"text-subtitle-2"},[_vm._v("Rangkuman diskusi yang sedang kamu ikuti.")])])],1),_c(VRow,[_c(VCol,{staticClass:"py-0",attrs:{"cols":"12","md":"auto"}},[_c(VTabs,{attrs:{"right":false},on:{"change":_vm.fetchActivityComments},model:{value:(_vm.objectFilter),callback:function ($$v) {_vm.objectFilter=$$v},expression:"objectFilter"}},[_c(VTab,[_vm._v(" Task ")]),_c(VTab,[_vm._v(" Job ")])],1)],1)],1)],1)])],1),(!_vm.activityCommentList.length)?_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center align-center mt-8"},[_c('span',{staticClass:"text--disabled"},[_vm._v("Belum ada diskusi")])])]):_vm._e(),_vm._l((_vm.activityCommentList),function(activity,index){return _c(VCol,{key:index,attrs:{"cols":"12","md":"6","lg":"4"}},[_c('comment-box',{key:index,attrs:{"expand":"","toggleable":"","expand-height":"20","job-id":activity.job_id,"task-id":activity.task_id},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c(VCard,{staticClass:"pa-2 mr-2 d-flex align-center",attrs:{"outlined":""},on:{"click":function($event){return _vm.resolveHeaderClick(activity)}}},[_c('span',{staticClass:"text-subtitle-2 primary--text mr-4"},[_vm._v(" "+_vm._s(activity.name)+" ")]),_c(VIcon,{staticClass:"primary--text",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiOpenInNew)+" ")])],1)]},proxy:true}],null,true)})],1)}),_c('task-detail',{ref:"taskDetail"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }